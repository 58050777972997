import Flyout from "../components/Flyout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Blogs = () => {
  const { t } = useTranslation("blog");
  return (
    <div className=" self-stretch flex flex-row items-start justify-center gap-[30px] max-w-full text-left text-xl text-teal-green font-abeezee mq1500:flex-wrap">
      <div className="shadow-[0px_0px_20px_rgba(0,_0,_0,_0.15)] w-[600px] rounded-11xl bg-light-grey3 flex flex-col items-center justify-start p-[50px] box-border gap-[30px] min-w-[600px] max-w-full mq975:gap-[15px_30px] mq975:py-8 mq975:px-[25px] mq975:box-border mq975:min-w-full mq450:pt-[21px] mq450:pb-[21px] mq450:box-border mq1500:flex-1">
        <img
          className="self-stretch h-[262px] relative max-w-full overflow-hidden shrink-0 object-cover"
          loading="lazy"
          alt=""
          src="/image-13@2x.png"
        />
        <h1 className="m-0 self-stretch  text-21xl leading-[60px] font-normal font-source-sans-pro mq975:text-13xl mq975:leading-[48px] mq450:text-5xl mq450:leading-[36px]">
          {t("paragraph1_title")}
        </h1>
        <div className="self-stretch relative leading-[34px] font-open-sans mq450:text-base mq450:leading-[27px]  tracking-normal pb-2">
          {t("paragraph1")}
        </div>
        <Link to="/the-grandview-philosophy" className="no-underline">
          <button className="cursor-pointer py-[30px] px-[29px] bg-[transparent] rounded-31xl overflow-hidden flex flex-col items-center justify-center whitespace-nowrap border-[1px] border-solid border-teal-green hover:bg-mint-green hover:box-border hover:border-[1px] hover:border-solid hover:border-mint-green">
            <b className="h-[27px] relative text-xl inline-block font-abeezee text-left min-w-[111px] text-teal-green hover:text-mint-green">
              Learn More
            </b>
          </button>
        </Link>
      </div>
      <div className=" flex-1 flex flex-col items-center justify-start pt-0 px-0 pb-0 box-border gap-[30px] min-w-[671px] max-w-full text-21xl font-source-sans-pro mq975:min-w-full">
        <div className="shadow-[0px_0px_20px_rgba(0,_0,_0,_0.15)] self-stretch rounded-11xl bg-light-grey3 flex flex-col items-start justify-start pt-[50px] px-[50px] pb-[46.8px] gap-[10px] shrink-0 mq1500:pl-[25px] mq1500:pr-[25px] mq1500:box-border">
          <h1 className="m-0 self-stretch relative text-inherit leading-[60px] font-normal font-inherit mq975:text-13xl mq975:leading-[48px] mq450:text-5xl mq450:leading-[36px]">
            {t("title2")}
          </h1>
          <Flyout>
            <Link
              to="/start-relation"
              className="text-teal-green font-bold no-underline self-stretch relative text-xl  leading-[34px] font-abeezee mq450:text-base mq450:leading-[27px] "
            >
              Read More
            </Link>
          </Flyout>
        </div>
        <div className="self-stretch rounded-11xl bg-light-grey3 shadow-[0px_0px_20px_rgba(0,_0,_0,_0.15)] flex flex-col items-start justify-start pt-[50px] px-[50px] pb-[46.8px] gap-[10px] shrink-0 mq1500:pl-[25px] mq1500:pr-[25px] mq1500:box-border">
          <h1 className="m-0 self-stretch relative text-inherit leading-[60px] font-normal font-inherit mq975:text-13xl mq975:leading-[48px] mq450:text-5xl mq450:leading-[36px]">
            {t("title3")}
          </h1>
          <Flyout>
            <Link
              to="/market"
              className="text-teal-green font-bold no-underline self-stretch relative text-xl  leading-[34px] font-abeezee mq450:text-base mq450:leading-[27px] "
            >
              Read More
            </Link>
          </Flyout>
        </div>
        <div className="self-stretch rounded-11xl bg-light-grey3 shadow-[0px_0px_20px_rgba(0,_0,_0,_0.15)] flex flex-col items-start justify-start pt-[50px] px-[50px] pb-[46.8px] gap-[10px] shrink-0 mq1500:pl-[25px] mq1500:pr-[25px] mq1500:box-border">
          <h1 className="m-0 self-stretch relative text-inherit leading-[60px] font-normal font-inherit mq975:text-13xl mq975:leading-[48px] mq450:text-5xl mq450:leading-[36px]">
            {t("title4")}
          </h1>
          <Flyout>
            <Link
              to="/why-emd"
              className="text-teal-green font-bold no-underline self-stretch relative text-xl  leading-[34px] font-abeezee mq450:text-base mq450:leading-[27px] "
            >
              Read More
            </Link>
          </Flyout>
        </div>
        <div className="self-stretch rounded-11xl bg-light-grey3 shadow-[0px_0px_20px_rgba(0,_0,_0,_0.15)] flex flex-col items-start justify-start pt-[50px] px-[50px] pb-[46.8px] gap-[10px] shrink-0 mq1500:pl-[25px] mq1500:pr-[25px] mq1500:box-border">
          <h1 className="m-0 self-stretch relative text-inherit leading-[60px] font-normal font-inherit mq975:text-13xl mq975:leading-[48px] mq450:text-5xl mq450:leading-[36px]">
            Coming Soon
          </h1>
          <Flyout>
            <Link
              to="/#"
              className="text-teal-green font-bold no-underline self-stretch relative text-xl  leading-[34px] font-abeezee mq450:text-base mq450:leading-[27px] "
            >
              Read More
            </Link>
          </Flyout>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
